//======================================================================
// variable
//======================================================================
// imgディレクトリまでのパス
$img-dir: "/assets/img";

// コンテンツ幅
$content-width: 1100px;

// SP時の左右の余白（片方）
$sp-side-padding: 15px;

//=================================
// breakpoint
//=================================
// 下記2つの変数の間がタブレット表示
// タブレット表示なしの場合は2つの変数の値を「1px差」で設定すること

// $pc-bp以上がPC表示
$pc-bp: 1025px;

// $sp-bp以下がSP表示
$sp-bp: 767px;

//=================================
// font
//=================================
$font-1: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", YuGothic, sans-serif;
$font-2: "din-2014", "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", YuGothic, sans-serif;

//=================================
// color
//=================================
$black: #3c3c3c;

$white: #fff;

$gray-1: #d9d9d9;
$gray-2: #d8d8d8;
$gray-3: #dedede;
$gray-4: #d5d5d5;
$gray-5: #fafafa;
$gray-6: #e6e6e6;
$gray-7: #999;
$gray-8: #f2f2f2;
$gray-9: #fafafa;
$gray-10: #e5e5e5;
$gray-11: #f6f6f6;
$gray-12: #707070;
$gray-13: #f7f7f7;
$gray-14: #e0e0e0;
$gray-15: #c6c6c6;
$gray-16: #adadad;
$gray-17: #d1d1d1;
$gray-18: #ccc;
$gray-19: #b6b6b6;

$color-1: #65b0de;
$color-2: #f06290;
$color-3: #ffb64a;
$color-4: #f57979;
$color-5: #fbf8fb;
$color-6: #fee1b5;
$color-7: #c9ebff;
$color-8: #d9f8e6;
$color-9: #dfd3e3;
$color-10: #eaeba4;
$color-11: #d5eef1;
$color-12: #ffe2e8;
$color-13: #fef0b5;
$color-14: #ffcc80;
$color-15: #ed0d0b;
$color-16: #f8fcfd;
$color-17: #fffbf4;
$color-18: #fff692;
$color-19: #fff3df;
$color-20: #ffd698;
$color-21: #fff3dd;
$color-22: #fffafa;
$color-23: #fff7f7;
$color-24: #ffa7a7;
$color-25: #ffd6d6;
$color-26: #f2f7e8;
$color-27: #e7f5f5;
$color-28: #f5ebf3;
$color-29: #eaf5fc;
$color-30: #fdf2e7;
$color-31: #ebf0e9;
$color-32: #f6f1e6;
$color-33: #f2ece8;
$color-34: #ececf4;
$color-35: #fcebe9;
$color-36: #e9f0f7;
$color-37: #fdf1f1;
$color-38: #f4fbff;
$color-39: #eaf7ff;
$color-40: #c9ebfd;
$color-41: #f4fff9;
$color-42: #fbfff4;
$color-43: #eaf7ff;
$color-44: #f5eaf5;
$color-45: #f5ffe2;
$color-46: #c9ebfd;
$color-47: #e8f795;
$color-48: #fed6d6;
$color-49: #ffd7d3;
$color-50: #ffc7c7;
$color-51: #dbc2e3;
$color-52: #e2d6e6;
$color-53: #deedc1;
$color-54: #fd7575;
$color-55: #fff3df;
$color-56: #d1f8e2;
$color-57: #fff4f4;
$color-58: #88e5b0;
$color-59: #fdc3e4;
$color-60: #6796b2;
$color-61: #e06969;
$color-62: #fff8f8;
$color-63: #71b1d8;
$color-64: #c9e2d3;

$color-course-1: #ffb64a;
$color-course-2: #ff9ab9;
$color-course-2-2: #ffa7a7;
$color-course-3: #65b0de;
$color-course-4: #77ba95;
$color-course-5: #caacd5;
$color-course-6: #bbe076;

$color-button-1: #5ba6d5;
$color-button-1-hover: #4690be;
$color-button-2: #fd7575;
$color-button-2-hover: #c36060;
$color-button-3: #06c755;
$color-button-3-hover: #29a75c;

$color-shadow-1: rgba(0, 0, 0, .05);
$color-shadow-2: rgba(255, 255, 255, .9);
$color-shadow-3: rgba(0, 0, 0, .1);
$color-shadow-4: rgba(255, 255, 255, .95);
$color-shadow-5: rgba(0, 0, 0, .16);
$color-shadow-6: rgba(0, 0, 0, .06);

//=================================
// CSS Transition Easing
//=================================
// Default
$linear: cubic-bezier(.250, .250, .750, .750);
$ease: cubic-bezier(.250, .100, .250, 1.000);
$ease-in: cubic-bezier(.420, .000, 1.000, 1.000);
$ease-out: cubic-bezier(.000, .000, .580, 1.000);
$ease-in-out: cubic-bezier(.420, .000, .580, 1.000);
// In
$easeInQuad: cubic-bezier(.550, .085, .680, .530);
$easeInCubic: cubic-bezier(.550, .055, .675, .190);
$easeInQuart: cubic-bezier(.895, .030, .685, .220);
$easeInQuint: cubic-bezier(.755, .050, .855, .060);
$easeInSine: cubic-bezier(.470, .000, .745, .715);
$easeInExpo: cubic-bezier(.950, .050, .795, .035);
$easeInCirc: cubic-bezier(.600, .040, .980, .335);
$easeInBack: cubic-bezier(.600, -.280, .735, .045);
// Out
$easeOutQuad: cubic-bezier(.250, .460, .450, .940);
$easeOutCubic: cubic-bezier(.215, .610, .355, 1.000);
$easeOutQuart: cubic-bezier(.165, .840, .440, 1.000);
$easeOutQuint: cubic-bezier(.230, 1.000, .320, 1.000);
$easeOutSine: cubic-bezier(.390, .575, .565, 1.000);
$easeOutExpo: cubic-bezier(.190, 1.000, .220, 1.000);
$easeOutCirc: cubic-bezier(.075, .820, .165, 1.000);
$easeOutBack: cubic-bezier(.175, .885, .320, 1.275);
// In Out
$easeInOutQuad: cubic-bezier(.455, .030, .515, .955);
$easeInOutCubic: cubic-bezier(.645, .045, .355, 1.000);
$easeInOutQuart: cubic-bezier(.770, .000, .175, 1.000);
$easeInOutQuint: cubic-bezier(.860, .000, .070, 1.000);
$easeInOutSine: cubic-bezier(.445, .050, .550, .950);
$easeInOutExpo: cubic-bezier(1.000, .000, .000, 1.000);
$easeInOutCirc: cubic-bezier(.785, .135, .150, .860);
$easeInOutBack: cubic-bezier(.680, -.550, .265, 1.550);


//======================================================================
// mixin
//======================================================================

//=================================
// clearfix
//=================================
@mixin cf {
  &:after {
    display: block;
    clear: both;
    content: "";
  }
}

//=================================
// safe-area
//=================================
@mixin safe-area {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-right: constant(safe-area-inset-right);
  padding-right: env(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);
  padding-left: env(safe-area-inset-left);
}

//=================================
// aタグのカラー変更
//=================================
@mixin a-tag($color1, $color2) {
  &:link,
  &:active,
  &:visited {
    color: $color1;
    cursor: pointer;
  }
  &:hover {
    color: $color2;
  }
}

//=================================
// プレースホルダーのカラー変更
//=================================
@mixin placeholder($color) {
  &:placeholder-shown {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

//=================================
// media query
//=================================
@mixin mq($min-width, $max-width) {
  @media screen and (min-width: $min-width + px) and (max-width: $max-width + px) {
    @content;
  }
}
@mixin mq-min($width) {
  @media screen and (min-width: $width + px) {
    @content;
  }
}
@mixin mq-max($width) {
  @media screen and (max-width: $width + px) {
    @content;
  }
}

// device
@mixin pc {
  @media screen and (min-width: $pc-bp) {
    @content;
  }
}
@mixin sp {
  @media screen and (max-width: $sp-bp) {
    @content;
  }
}
@mixin tab {
  @media screen and (min-width: $sp-bp + 1px) and (max-width: $pc-bp - 1px) {
    @content;
  }
}
@mixin pc-tab {
  @media screen and (min-width: $sp-bp + 1px) {
    @content;
  }
}
@mixin sp-tab {
  @media screen and (max-width: $pc-bp - 1px) {
    @content;
  }
}
@mixin pc-content {
  @media screen and (min-width: $pc-bp) and (max-width: $content-width) {
    @content;
  }
}

//=================================
// css hack
//=================================
// Chrome(and Safari, Opera)
@mixin chrome {
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @content;
  }
}
// FireFox
@mixin firefox {
  -:lang(x)::-moz-placeholder,
  & {
    @content;
  }
}
// Edge
@mixin edge {
  -:lang(x)::-ms-,
  & {
    @content;
  }
}
// IE(11)
@mixin ie {
  -:-ms-lang(x)::-ms-backdrop,
  & {
    @content;
  }
}
// Safari
@mixin safari {
  -:lang(x) + -:-webkit-full-screen-document,
  & {
    @content;
  }
}

//=================================
// object fit images
//=================================
@mixin ofi($size) {
  object-fit: $size;

  @include ie {
    font-family: "object-fit: #{$size};";
  }
}

//=================================
// hide scrollbar
//=================================
@mixin hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
