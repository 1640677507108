@use "../setting" as *;

.notfound {
  margin: 120px auto 0;

  @include sp {
    margin: 60px auto 0;
    padding: 0;
  }
}

.notfound__content {
  display: flex;
  align-items: center;
  padding: 50px;
  border-radius: 20px;
  background-color: $gray-5;

  @include sp {
    flex-direction: column-reverse;
    padding: 40px 15px;
    border-radius: 0;
  }
}

.notfound__text-wrap {
  @include sp {
    margin: 15px 0 0;
  }
}

.notfound__list {
  margin: 15px 0 0;
}

.notfound__p {
  margin: 15px 0 0;
}

.notfound__image-wrap {
  flex-shrink: 0;
  margin-left: 40px;

  @include sp {
    margin-left: 0;
  }
}

.notfound__button {
  margin: 50px auto 0;
  width: 380px;

  @include sp {
    margin: 30px auto 0;
    width: 315px;
  }
}

.guide {
  margin: 80px auto 0;

  @include sp {
    margin: 60px auto 0;
  }
}

.guide__content {
  margin: 40px auto 0;

  @include sp {
    margin: 30px auto 0;
  }
}
